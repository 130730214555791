import React from "react";
import {
  Button,
  Box,
  Text,
  Heading,
  AspectRatio,
  SimpleGrid,
  Container,
  useMediaQuery
} from "@chakra-ui/react";
import { navigate } from "gatsby";
import aboutUsBg from "../../images/image-21.png";
import aboutUsBgMobile from "../../images/image-21-mobile.png";

const HomeAboutUs = () => {
  const [isMobile] = useMediaQuery("(max-width: 820px)");

  const handleClick = () => {
    navigate("/about-us");
  };

  return (
    <>
      <SimpleGrid
        columns={{ base: 1, lg: 2 }}
        bgImage={{ base: aboutUsBgMobile, lg: aboutUsBg }}
        mt={{ base: "7.5em" }}
        bgRepeat="no-repeat"
        bgSize="cover">
        <Container maxW={{ lg: "container.sm" }} order={{ base: 2, lg: 1 }}>
          <Box
            textAlign={{ base: "center", lg: "left" }}
            mb={isMobile ? "20px" : "0px"}
            p={{ base: "40px", lg: "100px" }}>
            <Heading mb={4} as="h3" size="xl" color="white">
              #NeverFade
            </Heading>
            <Box maxW="320px" margin={{ base: "0 auto", lg: 0 }}>
              <Text mb={8} fontSize="xl" color="white">
                Untuk usahamu mewujudkan mimpi yang tak pernah hilang dengan OMG Oh My Glam dan Oh
                My Glow
              </Text>
            </Box>
            <Button
              bgColor="black"
              transform="scale(1)"
              transition="transform 500ms"
              size="lg"
              color="white"
              colorScheme="blackAlpha"
              borderRadius="30px"
              bg="#262626"
              onClick={handleClick}
              _focus={{ boxShadow: "none" }}
              _hover={{
                bg: "black",
                transform: "scale(1.05)",
                transition: "transform 500ms"
              }}>
              About Us
            </Button>
          </Box>
        </Container>
        <Container maxW={{ lg: "container.md" }} order={{ base: 1, lg: 2 }}>
          <AspectRatio
            maxW={{ base: "360px", md: "460px", lg: "460px", xl: "560px" }}
            margin={{ base: "0 auto", lg: "0" }}
            bottom="80px"
            boxShadow={{ base: "none", lg: "16px 16px #000000" }}
            ratio={16 / 9}>
            <iframe
              title="about-us-video"
              src="https://www.youtube.com/embed/mfa4uUiOBVs"
              allowFullScreen
            />
          </AspectRatio>
        </Container>
      </SimpleGrid>
    </>
  );
};

export default HomeAboutUs;
