import React from "react";
import qore from "../../context/qoreContext";
import { Container, Text, Box, Link, Image, Grid, GridItem } from "@chakra-ui/react";
import Skeleton from "react-loading-skeleton";
import Slider from "react-slick";
import "../../css/banner.css";

const OmgOfTheMonth = () => {
  const { data: contents, status } = qore.view("activeContent").useListRow({
    "$by.index": "asc"
  });
  const skeletonCount = [1, 2, 3, 4];

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4.5,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <Container
      maxW={{ lg: "container.md", xl: "container.xl" }}
      py={{ base: "60px", md: "70px", xl: "80px" }}>
      <Box textAlign="center">
        <Text fontSize={"24px"} fontWeight="bold">
          OMG of the Month
        </Text>
        <Text fontSize={{ base: "18px", md: "20px" }}>
          Tag us on{" "}
          <Link color="#991C21" href="https://www.instagram.com/itsomgbeauty/" target="_blank">
            @itsomgbeauty
          </Link>{" "}
          to be featured
        </Text>
      </Box>
      <Box py={10} overflow="hidden">
        {status === "success" ? (
          <Slider {...settings}>
            {contents.length &&
              contents.map((content, idx) => (
                <Box
                  px={2}
                  key={idx}
                  minH={{ base: "140px", md: "200px", lg: "220px", xl: "280px" }}
                  minW={{ base: "140px", md: "200px", lg: "220px", xl: "280px" }}
                  transform="scale(0.95)"
                  transition="transform 500ms"
                  _hover={{ transform: "scale(1)", transition: "transform 500ms" }}>
                  <a href={content.link} target="_blank" rel="noreferrer">
                    <Image
                      src={content.imgWebp}
                      fallbackSrc={content.img}
                      alt={content?.description || `content-${idx}`}
                      objectFit="cover"
                      boxSize={{ base: "140px", md: "200px", lg: "220px", xl: "280px" }}
                      loading="lazy"
                    />
                  </a>
                </Box>
              ))}
          </Slider>
        ) : (
          <Grid
            templateColumns={{ base: "repeat(3, 1fr)", md: "repeat(4, 1fr)" }}
            gap={6}
            overflow="hidden">
            {skeletonCount.map(skeleton => (
              <GridItem
                key={skeleton}
                h={{ base: "140px", md: "200px" }}
                w={{ base: "140px", md: "200px" }}
                display={skeleton === 4 ? { base: "none", md: "block" } : { base: "block" }}>
                <Skeleton height="100%" width="100%" />
              </GridItem>
            ))}
          </Grid>
        )}
      </Box>
    </Container>
  );
};

export default OmgOfTheMonth;
