import React from "react";
import { SimpleGrid, Box } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const QuizPollingBanner = () => {
  return (
    <>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={0}>
        <Box>
          <Link to={`/skincare-quiz`}>
            <StaticImage
              layout="fullWidth"
              objectFit="contain"
              src="../../images/homepage/quiz.png"
              alt="skincare-quiz"
              loading="lazy"
            />
          </Link>
        </Box>
        <Box>
          <Link to={`/skincare-poll`}>
            <StaticImage
              layout="fullWidth"
              objectFit="contain"
              src="../../images/homepage/poll.png"
              alt="skincare-polling"
              loading="lazy"
            />
          </Link>
        </Box>
        <Box>
          <a href="https://www.instagram.com/ar/3070444003235363/" target="_blank" rel="noreferrer">
            <StaticImage
              layout="fullWidth"
              objectFit="contain"
              src="../../images/homepage/makeup-vto.png"
              alt="coba-filter"
              loading="lazy"
            />
          </a>
        </Box>
        <Box>
          <Link to={`/makeup-quiz`}>
            <StaticImage
              layout="fullWidth"
              objectFit="contain"
              src="../../images/homepage/makeup-quiz.png"
              alt="makeup-quiz"
              loading="lazy"
            />
          </Link>
        </Box>
      </SimpleGrid>
    </>
  );
};

export default QuizPollingBanner;
