import React from "react";
import qore from "../../context/qoreContext";
import { Container, Text, Box, Image, Grid, GridItem, IconButton } from "@chakra-ui/react";
import { FaChevronRight } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { Link } from "gatsby";
import Slider from "react-slick";
import "../../css/banner.css";

const BeautyTips = () => {
  const { data: contents, status } = qore
    .view("publishedTips")
    .useListRow({ "$by.index": "asc", limit: 5 });
  const skeletonCount = [1, 2, 3, 4];

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4.5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <Container
      maxW={{ lg: "container.md", xl: "container.xl" }}
      py={{ base: "60px", md: "70px", xl: "80px" }}>
      <Box textAlign="center">
        <Text fontSize={"24px"} fontWeight="bold">
          Beauty Tips
        </Text>
        <Text fontSize={{ base: "18px", md: "20px" }}>
          Cari tahu tips and tricks seru untuk membuatmu selalu tampak cantik and glam
        </Text>
      </Box>
      <Box py={10} overflow="hidden">
        {status === "success" ? (
          <Slider {...settings}>
            {contents.length &&
              contents.map((content, idx) => (
                <Box
                  px={2}
                  key={idx}
                  transform="scale(0.95)"
                  transition="transform 500ms"
                  _before={
                    idx === 4
                      ? {
                          content: "''",
                          display: "block",
                          width: "95%",
                          height: "100%",
                          backgroundImage:
                            "linear-gradient(to bottom, rgba(146, 0, 0, 1), rgba(146, 0, 0, 0.5))",
                          position: "absolute",
                          bottom: 0
                        }
                      : null
                  }
                  _hover={{ transform: "scale(1)", transition: "transform 500ms" }}>
                  <Link to={idx === 4 ? `/beauty-tips` : `/beauty-tips/article/${content.slug}`}>
                    <Image
                      src={content.imgWebp || content.img}
                      fallbackSrc={content.img}
                      alt={content?.description || `content-${idx}`}
                      objectFit="cover"
                      loading="lazy"
                    />
                    {idx === 4 && (
                      <Box
                        position={"absolute"}
                        top="40%"
                        right="0"
                        bottom="0"
                        left="0"
                        textAlign={"center"}>
                        <Text color={"white"} fontSize={{ base: "18px", md: "24px" }}>
                          Lihat Semua Tips
                        </Text>
                        <IconButton
                          colorScheme={"blackAlpha"}
                          borderRadius={"90px"}
                          bgColor={"transparent"}
                          border="1px solid white"
                          color="white"
                          icon={<FaChevronRight />}
                        />
                      </Box>
                    )}
                  </Link>
                </Box>
              ))}
          </Slider>
        ) : (
          <Grid
            templateColumns={{ base: "repeat(3, 1fr)", md: "repeat(4, 1fr)" }}
            gap={6}
            overflow="hidden">
            {skeletonCount.map(skeleton => (
              <GridItem
                key={skeleton}
                h={{ base: "140px", md: "200px" }}
                w={{ base: "140px", md: "200px" }}
                display={skeleton === 4 ? { base: "none", md: "block" } : { base: "block" }}>
                <Skeleton height="100%" width="100%" />
              </GridItem>
            ))}
          </Grid>
        )}
      </Box>
    </Container>
  );
};

export default BeautyTips;
