import React from "react";
import { SimpleGrid, Box } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const CategoryBanner = () => {
  return (
    <>
      <SimpleGrid columns={{ base: 1, md: 2 }}>
        <Box>
          <Link to="/make-up">
            <StaticImage
              layout="fullWidth"
              objectFit="contain"
              src="../../images/homepage/see-makeup.png"
              alt="quiz-banner"
              loading="lazy"
            />
          </Link>
        </Box>
        <Box>
          <Link to="/skincare">
            <StaticImage
              layout="fullWidth"
              objectFit="contain"
              src="../../images/homepage/see-skincare.png"
              alt="quiz-banner"
              loading="lazy"
            />
          </Link>
        </Box>
      </SimpleGrid>
    </>
  );
};

export default CategoryBanner;
