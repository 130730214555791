import React from "react";
import qore from "../../context/qoreContext";
import { Box } from "@chakra-ui/react";
import styled from "styled-components";
import Slider from "react-slick";
import Skeleton from "react-loading-skeleton";

// Import css files
import "../../css/banner.css";

const SliderContainter = styled(Box)`
  overflow-x: hidden;

  li button::before {
    color: white;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    min-height: 20vh;
    li button::before {
      font-size: 10px;
    }
  }
`;

const CustomSlider = styled(Slider)`
  .slick-dots {
    bottom: 16px;
  }
`;

const CustomCard = styled(Box)`
  /* margin: 64px auto; */
  border: 0;

  @media screen and (max-width: 767px) {
    margin: 0;
  }

  @media screen and (min-width: 768px) {
    .active-card-home {
      transform: scale(1);
      transition: transform 500ms;
    }

    .normal-card-home {
      transform: scale(0.98);
      transition: transform 500ms;
      pointer-events: none;
    }
  }
`;

const ContainerCard = styled(Box)`
  padding: 40px 0;
  @media screen and (max-width: 767px) {
    padding: 0;
  }
`;

const ImgWithFallback = ({ src, fallback, alt, type = "image/webp", ...delegated }) => {
  return (
    <picture>
      <source srcSet={src} type={type} alt={alt} />
      <img loading="lazy" src={fallback} {...delegated} alt={alt} />
    </picture>
  );
};

const FallbackWeb = styled(ImgWithFallback)`
  max-height: 93%;
  width: 93%;
  margin: 0 auto;

  @media screen and (max-width: 767px) {
    display: none !important;
  }
`;
const FallbackMobile = styled(ImgWithFallback)`
  display: none !important;
  width: 100vw;
  height: auto;

  @media screen and (max-width: 767px) {
    display: inline !important;
  }
`;

const HomeBanner = () => {
  const [imageIndex, setImageIndex] = React.useState(0);
  const { data: banners, status } = qore.view("activeBanner").useListRow({
    "$by.index": "asc"
  });
  const skeletonCount = [0, 1, 2];

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "120px",
    focusOnSelect: true,
    slidesToShow: 1,
    speed: 500,
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
    beforeChange: (current, next) => setImageIndex(next),
    responsive: [
      {
        breakpoint: 480,
        settings: {
          centerPadding: "0px",
          centerMode: false,
          focusOnSelect: false
        }
      }
    ]
  };

  return (
    <>
      <SliderContainter
        minH="60vh"
        background={{
          base: "linear-gradient(to right, #db736b 70%, #e89c96 100%)",
          md: "linear-gradient(180deg, #f6c8bd 0%, #991c21 100%)"
        }}>
        <CustomSlider {...settings}>
          {status === "success"
            ? banners.map((banner, idx) => (
                <ContainerCard key={idx}>
                  <CustomCard
                    className={idx === imageIndex ? "active-card-home" : "normal-card-home"}>
                    <a href={banner.link} target="_blank" rel="noopener noreferrer">
                      <FallbackWeb
                        src={banner.imgWebp}
                        fallback={banner.img}
                        alt="This is home banner"
                      />
                      <FallbackMobile
                        src={banner.imgMobileWebp}
                        fallback={banner.imgMobile}
                        alt="This is mobile home banner"
                      />
                    </a>
                  </CustomCard>
                </ContainerCard>
              ))
            : skeletonCount.map(skeleton => (
                <Box p={{ base: 0, lg: "40px 0" }} key={skeleton}>
                  <CustomCard
                    px={{ base: 0, lg: 10 }}
                    className={skeleton === imageIndex ? "active-card-home" : "normal-card-home"}>
                    <Skeleton height={"60vh"} />
                  </CustomCard>
                </Box>
              ))}
        </CustomSlider>
      </SliderContainter>
    </>
  );
};

export default HomeBanner;
