import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import HomeAboutUs from "../components//homepage/HomeAboutUs";
import SubsForm from "../components/homepage/SubsForm";
import HomeBanner from "../components/homepage/HomeBanner";
import OmgOfTheMonth from "../components/homepage/OmgOfTheMonth";
import QuizPollingBanner from "../components/homepage/QuizPollingBanner";
import BeautyTips from "../components/homepage/BeautyTips";
import CategoryBanner from "../components/homepage/CategoryBanner";
import MakeupSlider from "../components/homepage/MakeupSlider";
import SkincareSlider from "../components/homepage/SkincareSlider";

import { useDisclosure } from "@chakra-ui/react";

const IndexPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  React.useEffect(() => {
    const hours = 24; // Reset when storage is more than 24hours
    const now = new Date().getTime();
    const popupTime = localStorage.getItem("popupTime");

    if (popupTime === null) {
      onOpen();
      localStorage.setItem("popupTime", now);
    } else if (now - popupTime > hours * 60 * 60 * 1000) {
      localStorage.removeItem("popupTime");
    }
  }, []);

  return (
    <Layout>
      <Seo title="Home" />
      <HomeBanner />
      <SkincareSlider />
      <MakeupSlider />
      <SubsForm isOpen={isOpen} onClose={onClose} />
      <HomeAboutUs />
      <OmgOfTheMonth />
      <QuizPollingBanner />
      <BeautyTips />
      <CategoryBanner />
    </Layout>
  );
};

export default IndexPage;
