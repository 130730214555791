import React, { useState } from "react";
import qore from "../../context/qoreContext";
import Slider from "react-slick";
import styled from "styled-components";
import {
  Modal,
  Button,
  Box,
  Text,
  Flex,
  Spacer,
  Heading,
  Image,
  useMediaQuery,
  IconButton,
  Container,
  VStack,
  Grid,
  GridItem,
  Center
} from "@chakra-ui/react";

import { Link } from "gatsby";
import slugify from "../slugify";

import { FaLongArrowAltRight, FaLongArrowAltLeft } from "react-icons/fa";
import ProductModal from "../product/ProductModal";
import { StaticImage } from "gatsby-plugin-image";
import Skeleton from "react-loading-skeleton";

import "../../css/banner.css";

const CustomSlider = styled(Slider)`
  .slick-dots {
    bottom: 0;
  }
`;

const SliderHeading = styled(Heading)`
  font-family: "AvenirBold", sans-serif !important;
`;

const MakeupSlider = () => {
  const [modalShow, setModalShow] = useState(false);
  const [popupData, setPopupData] = useState({});
  const [isMobile] = useMediaQuery("(max-width: 820px)");
  const skeletonCount = [1, 2, 3, 4, 5];

  const { data, status } = qore.views.makeupProducts.useListRow({
    "$by.parentName": "asc",
    "$by.no": "asc"
  });

  const customSlider = React.createRef();

  const handleClose = () => {
    setPopupData({});
    setModalShow(false);
  };

  const handleClick = data => {
    const obj = {
      name: data.name,
      imgSwatch: data.imgProduct,
      tokopedia: data.tokopedia,
      shopee: data.shopee,
      lazada: data.lazada,
      blibli: data.blibli,
      tiktok: data.tiktok,
      toko: data.toko,
      whatsapp: data.whatsapp,
      isSachet: data.isSachet,
      type: data.type,
      no: data.no
    };
    setPopupData(obj);
    setModalShow(true);
  };

  const nextSlide = () => {
    customSlider.current.slickNext();
  };

  const prevSlide = () => {
    customSlider.current.slickPrev();
  };

  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    initialSlide: 0,
    slidesToShow: 4.5,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3.5
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3.5
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.5
        }
      }
    ]
  };

  const CardImage = styled(Box)`
    background: ${props => props.ringcolor};
    border-radius: 50%;
    margin: 0 auto;
  `;

  return (
    <>
      <Box
        p={{ base: "15px 30px", lg: "40px 100px" }}
        overflow="hidden"
        bgSize="100%"
        bgRepeat="no-repeat">
        <Container px={{ base: 0, md: "auto" }} pt={8} maxW="container.fluid">
          <Flex pb="3vh">
            <SliderHeading fontSize={{ base: "16px", md: "26px" }} mb={1}>
              Make Up
            </SliderHeading>
            <Spacer />
            <Box>
              <IconButton
                icon={<FaLongArrowAltLeft />}
                h={isMobile ? 8 : 10}
                w={isMobile ? 16 : 20}
                bgColor="white"
                color="black"
                colorScheme="blackAlpha"
                mr="15px"
                borderRadius="20px"
                borderColor="black"
                borderWidth="thin"
                _focus={{ boxShadow: "none" }}
                onClick={() => prevSlide()}
              />
              <IconButton
                icon={<FaLongArrowAltRight />}
                h={isMobile ? 8 : 10}
                w={isMobile ? 16 : 20}
                bgColor="black"
                color="white"
                colorScheme="blackAlpha"
                borderRadius="20px"
                borderColor="white"
                _focus={{ boxShadow: "none" }}
                onClick={() => nextSlide()}
              />
            </Box>
          </Flex>
          {status === "success" ? (
            <CustomSlider {...sliderSettings} ref={customSlider}>
              {data.map(product => (
                <VStack
                  key={product.id}
                  spacing={8}
                  mt={6}
                  mb={16}
                  transform="scale(0.95)"
                  transition="transform 500ms"
                  _hover={{ transform: "scale(1)", transition: "transform 500ms" }}>
                  <CardImage
                    h={{ base: "120px", md: "160px", xl: "200px" }}
                    w={{ base: "120px", md: "160px", xl: "200px" }}
                    ringcolor={product.hexColor}>
                    <Link to={`/make-up/details/${slugify(product?.parentName)}/${product.no}`}>
                      <Image
                        src={product?.imgProduct}
                        alt={product?.name}
                        w={{ base: "120px", md: "150px", xl: "180px" }}
                        h={{ base: "160px", md: "200px", xl: "240px" }}
                        objectFit="contain"
                        loading="lazy"
                        m="0 auto"
                        position="relative"
                        bottom="20px"
                        transform="rotate(-12deg)"
                      />
                      {product?.bestSeller && (
                        <Box
                          position="absolute"
                          right={{ base: "0", md: "24px", lg: "32px", xl: "96px" }}
                          maxH={{ base: "30px", md: "32px", lg: "32px", xl: "40px" }}
                          top="4px">
                          <StaticImage
                            src="../../images/best-seller2.png"
                            alt="best-seller"
                            loading="lazy"
                            objectFit="contain"
                            style={{ maxHeight: "inherit" }}
                          />
                        </Box>
                      )}
                    </Link>
                  </CardImage>
                  <Box textAlign="left">
                    <Center>
                      <Link to={`/make-up/details/${slugify(product?.parentName)}/${product.no}`}>
                        <Text mb={3}>{product?.parentName}</Text>
                        <Text mb={3} fontWeight="bold">{`${product?.no} ${product?.name}`}</Text>
                        <Text>{`Rp ${product?.price.toLocaleString("id-ID", {
                          maximumFractionDigits: 0
                        })}`}</Text>
                      </Link>
                    </Center>
                    <Box mt={6} textAlign="center">
                      <Button
                        size="sm"
                        width={"88px"}
                        rounded="xl"
                        bg="#262626"
                        colorScheme="blackAlpha"
                        color="white"
                        onClick={() => handleClick(product)}
                        _focus={{ boxShadow: "none" }}
                        _hover={{
                          bg: "black"
                        }}>
                        Beli
                      </Button>
                    </Box>
                  </Box>
                </VStack>
              ))}
            </CustomSlider>
          ) : (
            <Grid
              templateColumns={{ base: "repeat(3, 1fr)", md: "repeat(5, 1fr)" }}
              gap={2}
              overflow="hidden">
              {skeletonCount.map(skeleton => (
                <GridItem
                  key={skeleton}
                  h={{ base: "160px", xl: "200px" }}
                  w={{ base: "160px", xl: "200px" }}
                  p={1}
                  display={skeleton > 3 ? { base: "none", md: "block" } : { base: "block" }}>
                  <Skeleton circle="true" height="100%" width="100%" />
                </GridItem>
              ))}
            </Grid>
          )}
        </Container>
      </Box>
      <Modal
        isOpen={modalShow}
        size={isMobile ? "sm" : "2xl"}
        onClose={() => handleClose()}
        isCentered={true}>
        <ProductModal PopUpData={popupData} />
      </Modal>
    </>
  );
};

export default MakeupSlider;
