import React from "react";
import {
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  ModalBody,
  Box,
  Flex,
  Heading,
  Image,
  useMediaQuery,
  SimpleGrid,
  Center
} from "@chakra-ui/react";

import blibli from "../../images/blibli.png";
import lazada from "../../images/lazada.png";
import shopee from "../../images/shopee.png";
import tiktok from "../../images/tiktok.png";
import toko from "../../images/toko.png";
import tokopedia from "../../images/tokopedia.png";
import whatsapp from "../../images/whatsapp.png";
import PropTypes from "prop-types";

const ProductModal = ({ PopUpData }) => {
  const [isMobile] = useMediaQuery("(max-width: 820px)");

  const BoxStyle = {
    boxShadow: "5px 5px 1px",
    borderStyle: "solid",
    borderColor: "black",
    borderWidth: "2px"
  };

  return (
    <>
      <ModalOverlay />
      <ModalContent bgSize="100%">
        <ModalCloseButton />
        <ModalBody
          backgroundImage="linear-gradient(#F6C8BD, #991C21)"
          padding={isMobile ? "30px 20px 0px 20px" : 0}>
          <Box>
            {isMobile ? (
              <Flex direction="column">
                <Box alignItems="center">
                  <Heading mb={4} as="h3" size="md">
                    Pilih untuk melanjutkan
                  </Heading>
                  <Heading mb={3} as="h3" size="md">
                    OMG Official Store
                  </Heading>
                  <SimpleGrid columns={2} spacing={6} mb={6}>
                    <Center bg="white" height="50px" style={BoxStyle}>
                      <a href={PopUpData.whatsapp} target="_blank" rel="noopener noreferrer">
                        <Image width="150%" height="150%" layout="constrained" src={whatsapp} />
                      </a>
                    </Center>
                    <Center bg="white" height="50px" style={BoxStyle}>
                      <a href={PopUpData.tiktok} target="_blank" rel="noopener noreferrer">
                        <Image width="150%" height="150%" layout="constrained" src={tiktok} />
                      </a>
                    </Center>
                    <Center bg="white" height="50px" style={BoxStyle}>
                      <a href={PopUpData.shopee} target="_blank" rel="noopener noreferrer">
                        <Image width="150%" height="150%" layout="constrained" src={shopee} />
                      </a>
                    </Center>
                    <Center bg="white" height="50px" style={BoxStyle}>
                      <a href={PopUpData.tokopedia} target="_blank" rel="noopener noreferrer">
                        <Image width="150%" height="150%" layout="constrained" src={tokopedia} />
                      </a>
                    </Center>
                    <Center bg="white" height="50px" style={BoxStyle}>
                      <a href={PopUpData.lazada} target="_blank" rel="noopener noreferrer">
                        <Image width="150%" height="150%" layout="constrained" src={lazada} />
                      </a>
                    </Center>
                    <Center bg="white" height="50px" style={BoxStyle}>
                      <a href={PopUpData.blibli} target="_blank" rel="noopener noreferrer">
                        <Image width="150%" height="150%" layout="constrained" src={blibli} />
                      </a>
                    </Center>
                  </SimpleGrid>
                  <Heading mb={3} as="h3" size="md">
                    Toko Kosmetik Terdekat
                  </Heading>
                  <Center bg="white" height="50px" style={BoxStyle}>
                    <a href={PopUpData.toko} target="_blank" rel="noopener noreferrer">
                      <Image width="150%" height="150%" layout="constrained" src={toko} />
                    </a>
                  </Center>
                  <Center
                    mt={PopUpData.type === "makeup" ? 8 : 2}
                    padding="10px 40px 0px 40px"
                    overflow="hidden">
                    <SimpleGrid columns={2}>
                      <Image
                        maxH={
                          PopUpData.type === "makeup"
                            ? "180px"
                            : PopUpData.name.includes("Glow")
                            ? "300px"
                            : "220px"
                        }
                        pr={
                          PopUpData.type === "makeup"
                            ? 0
                            : PopUpData.name.includes("Glow Kit")
                            ? 1
                            : 6
                        }
                        py={PopUpData.name.includes("Glow Kit") ? 3 : 0}
                        mb={8}
                        alt={PopUpData.name}
                        placeholder="blurred"
                        layout="constrained"
                        src={PopUpData.imgSwatch}
                        position="relative"
                        transform={
                          PopUpData.isSachet || PopUpData.name.includes("Glow Kit")
                            ? "scale(1.4)"
                            : ""
                        }
                      />
                      <Center>
                        <Heading
                          as="h3"
                          size="sm"
                          textColor="white"
                          textAlign={PopUpData.isSachet ? "center" : "left"}>
                          {PopUpData.type === "makeup"
                            ? `${PopUpData.no} ${PopUpData.name}`
                            : PopUpData.name}
                        </Heading>
                      </Center>
                    </SimpleGrid>
                  </Center>
                </Box>
              </Flex>
            ) : (
              <Flex direction="row">
                <Box w="45%" padding="80px 10px 80px 10px" backgroundColor="white">
                  <Center>
                    <Image
                      h={{ base: "160px", md: "200px", xl: "240px" }}
                      alt={PopUpData.name}
                      placeholder="blurred"
                      layout="constrained"
                      src={PopUpData.imgSwatch}
                      mb={4}
                    />
                  </Center>
                  <Heading as="h3" size="sm" margin="0 auto" width="120px" textAlign="center">
                    {PopUpData.type === "makeup"
                      ? `${PopUpData.no} ${PopUpData.name}`
                      : PopUpData.name}
                  </Heading>
                </Box>
                <Box w="55%" p="30px" alignItems="center">
                  <Heading mb={6} as="h3" size="md">
                    Pilih utuk melanjutkan
                  </Heading>
                  <Heading mb={3} as="h3" size="md">
                    OMG Official Store
                  </Heading>
                  <SimpleGrid columns={2} spacing={6} mb={6}>
                    <Center bg="white" height="50px" style={BoxStyle}>
                      <a href={PopUpData.whatsapp} target="_blank" rel="noopener noreferrer">
                        <Image width="150%" height="150%" layout="constrained" src={whatsapp} />
                      </a>
                    </Center>
                    <Center bg="white" height="50px" style={BoxStyle}>
                      <a href={PopUpData.tiktok} target="_blank" rel="noopener noreferrer">
                        <Image width="150%" height="150%" layout="constrained" src={tiktok} />
                      </a>
                    </Center>
                    <Center bg="white" height="50px" style={BoxStyle}>
                      <a href={PopUpData.shopee} target="_blank" rel="noopener noreferrer">
                        <Image width="150%" height="150%" layout="constrained" src={shopee} />
                      </a>
                    </Center>
                    <Center bg="white" height="50px" style={BoxStyle}>
                      <a href={PopUpData.tokopedia} target="_blank" rel="noopener noreferrer">
                        <Image width="150%" height="150%" layout="constrained" src={tokopedia} />
                      </a>
                    </Center>
                    <Center bg="white" height="50px" style={BoxStyle}>
                      <a href={PopUpData.lazada} target="_blank" rel="noopener noreferrer">
                        <Image width="150%" height="150%" layout="constrained" src={lazada} />
                      </a>
                    </Center>
                    <Center bg="white" height="50px" style={BoxStyle}>
                      <a href={PopUpData.blibli} target="_blank" rel="noopener noreferrer">
                        <Image width="150%" height="150%" layout="constrained" src={blibli} />
                      </a>
                    </Center>
                  </SimpleGrid>
                  <Heading mb={3} mt={6} as="h3" size="md">
                    Toko Kosmetik Terdekat
                  </Heading>
                  <Center bg="white" height="50px" style={BoxStyle}>
                    <a href={PopUpData.toko} target="_blank" rel="noopener noreferrer">
                      <Image width="150%" height="150%" layout="constrained" src={toko} />
                    </a>
                  </Center>
                </Box>
              </Flex>
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </>
  );
};

ProductModal.propTypes = {
  PopUpData: PropTypes.instanceOf(Object)
};

ProductModal.defaultProps = {
  PopUpData: {}
};

export default ProductModal;
