import React, { useState } from "react";
import qore from "../../context/qoreContext";
import Slider from "react-slick";
import styled from "styled-components";
import {
  Button,
  Box,
  Text,
  Flex,
  Spacer,
  Heading,
  Image,
  useMediaQuery,
  Modal,
  Container,
  VStack,
  Grid,
  GridItem
} from "@chakra-ui/react";
import { Link } from "gatsby";
import Skeleton from "react-loading-skeleton";
import ProductModal from "../product/ProductModal";
import skincareAccent from "../../images/skincareAccent.png";
import { StaticImage } from "gatsby-plugin-image";
import "../../css/banner.css";

const SliderContainter = styled(Box)`
  overflow: hidden;

  @media screen and (min-width: 0px) and (max-width: 1023px) {
    padding: 10px 30px;
  }

  @media screen and (min-width: 1024px) and (max-width: 3000px) {
    padding: 20px 100px;
  }
`;

const CustomHeading = styled(Heading)`
  display: none;

  @media screen and (min-width: 120px) and (max-width: 767px) {
    display: block;
  }
`;

const SliderHeading = styled(Heading)`
  font-family: "AvenirBold", sans-serif !important;
`;

const SkincareSlider = () => {
  const [modalShow, setModalShow] = useState(false);
  const [popupData, setPopupData] = useState({});
  const [isMobile] = useMediaQuery("(max-width: 820px)");
  const skeletonCount = [1, 2, 3, 4, 5];

  const { data, status } = qore.views.skincareProducts.useListRow({
    order: "asc"
  });

  const handleClose = () => {
    setPopupData({});
    setModalShow(false);
  };

  const handleClick = data => {
    const obj = {
      name: data.name,
      imgSwatch: data.imgBottle,
      tokopedia: data.tokopedia,
      shopee: data.shopee,
      lazada: data.lazada,
      blibli: data.blibli,
      tiktok: data.tiktok,
      toko: data.toko,
      whatsapp: data.whatsapp,
      isSachet: data.isSachet,
      type: data.type
    };
    setPopupData(obj);
    setModalShow(true);
  };

  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
      <SliderContainter
        bgImage={skincareAccent}
        bgSize={{ sm: "200%", md: "200%", lg: "100%" }}
        bgRepeat="no-repeat">
        <Container px={{ base: 0, md: "auto" }} pt={8} maxW="container.fluid">
          <Flex>
            <SliderHeading fontSize={{ base: "16px", md: "26px" }} mb={1}>
              Skin Care
            </SliderHeading>
            <Spacer />
            <CustomHeading size="sm">
              <Link to="/skincare">See All</Link>
            </CustomHeading>
          </Flex>
          {status === "success" ? (
            <Slider {...sliderSettings}>
              {data.map((productData, index) => (
                <>
                  <VStack
                    key={index}
                    spacing={6}
                    mb={8}
                    transform="scale(0.95)"
                    transition="transform 500ms"
                    _hover={{ transform: "scale(1)", transition: "transform 500ms" }}>
                    <Link to={`/skincare/detail/${productData?.name}`}>
                      <Image
                        src={productData?.imgBottle}
                        alt={productData?.name}
                        w={{ base: "120px", md: "150px", xl: "180px" }}
                        h={{ base: "180px", md: "200px", xl: "240px" }}
                        mb={2}
                        objectFit="contain"
                        loading="lazy"
                      />
                      {productData?.isNewProduct && (
                        <Box
                          position="absolute"
                          right={{ base: "45px", sm: "45px", md: "60px", xl: "120px" }}
                          top="4px">
                          <StaticImage
                            src="../../images/new-product.png"
                            alt="new-product"
                            height="40px !important"
                          />
                        </Box>
                      )}
                    </Link>
                    <Box textAlign="left" px={{ base: 2, md: "auto" }}>
                      <Text fontSize={{ base: "16px", md: "18px" }} minH="30px" width="140px">
                        Peach Glowing
                      </Text>
                      <Text fontSize={{ base: "16px", md: "18px" }} mb={1} fontWeight="bold">
                        {productData.name === "Glowing Toner"
                          ? productData.name.replace(/Glowing/g, "")
                          : productData.name.replace(/Peach Glowing/g, "")}
                      </Text>
                      <Text fontSize="lg" mb={1}>
                        Rp {productData.price.toLocaleString("id-ID", { maximumFractionDigits: 0 })}
                      </Text>
                    </Box>
                    <Box textAlign={{ base: "center", md: "left" }}>
                      <Button
                        size="sm"
                        width="88px"
                        borderRadius="25px"
                        bg="#262626"
                        colorScheme="blackAlpha"
                        color="white"
                        _focus={{ boxShadow: "none" }}
                        _hover={{
                          bg: "black"
                        }}
                        onClick={() => {
                          handleClick(productData);
                        }}>
                        Beli
                      </Button>
                    </Box>
                  </VStack>
                </>
              ))}
            </Slider>
          ) : (
            <Grid
              templateColumns={{ base: "repeat(3, 1fr)", md: "repeat(5, 1fr)" }}
              gap={2}
              overflow="hidden">
              {skeletonCount.map(skeleton => (
                <GridItem
                  key={skeleton}
                  h={{ base: "306px", md: "328px" }}
                  w={{ base: "126px", md: "135px" }}
                  display={skeleton > 3 ? { base: "none", md: "block" } : { base: "block" }}>
                  <Skeleton height="100%" width="100%" />
                </GridItem>
              ))}
            </Grid>
          )}
        </Container>
      </SliderContainter>
      <Modal
        isOpen={modalShow}
        size={isMobile ? "sm" : "2xl"}
        onClose={() => handleClose()}
        isCentered={true}>
        <ProductModal PopUpData={popupData} />
      </Modal>
    </>
  );
};

export default SkincareSlider;
