import React, { useState } from "react";
import qore from "../../context/qoreContext";
// import addToMailchimp from "gatsby-plugin-mailchimp"
import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  Button,
  ModalBody,
  Box,
  Input,
  Text,
  Flex,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Checkbox,
  Heading,
  Image,
  useMediaQuery,
  useBoolean
} from "@chakra-ui/react";
import PopupBg from "../../images/bg-popuphome.png";
import mobilePopupBg from "../../images/bg-popuphome-mobile.png";
import PopupImg from "../../images/popupimg.png";

const SubsForm = ({ isOpen, onClose }) => {
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [phone, setPhone] = useState("");
  const [isChecked, setIsChecked] = useState(true);
  const [loading, setLoading] = useBoolean(false);
  const [header, setHeader] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { insertRow } = qore.views.allSubscriber.useInsertRow();

  const handleChange = e => {
    setPhone(e.target.value);
    setErrorMessage("");
  };

  const handleChecked = e => {
    setIsChecked(e.target.checked);
    setErrorMessage("");
  };

  const resetHeaderData = () => {
    setHeader("");
  };

  const handleValidation = () => {
    if (phone && isChecked) {
      const isnum = /^\d+$/.test(phone);
      if (isnum && phone.length >= 10) {
        return true;
      } else {
        setErrorMessage("Anda memasukkan nomor telepon yang tidak valid");
        return false;
      }
    } else if (!phone) {
      setErrorMessage("Anda belum memasukkan nomor telepon");
      return false;
    } else if (!isChecked) {
      setErrorMessage("Anda belum menyetujui privacy policy");
      return false;
    }
  };

  const handleSubmit = async () => {
    const validation = handleValidation();
    if (validation) {
      try {
        setLoading.on();
        const phoneInserted = await insertRow({ phone });
        if (!phoneInserted) throw false;
        setPhone(null);
        setHeader("Success");
        setMessage("Thank you for subscribing");
      } catch (error) {
        console.error(error);
        setHeader("Error");
        setMessage("Server error, please try again");
      } finally {
        setLoading.off();
      }
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} size={isMobile ? "sm" : "3xl"} onClose={onClose} isCentered={true}>
        <ModalOverlay />
        <ModalContent
          bgImage={isMobile ? mobilePopupBg : PopupBg}
          bgSize="120%"
          style={isMobile ? { boxShadow: "" } : { boxShadow: "25px 25px 1px" }}>
          <ModalCloseButton />
          <ModalBody p={isMobile ? 6 : 8}>
            <Box>
              {isMobile ? (
                <Flex direction="column">
                  {header === "Success" ? (
                    <Box w="100%" padding="80px 5px 80px 5px" alignItems="center">
                      <Heading mb={4} as="h2" size="xl">
                        {header}
                      </Heading>
                      <Text mb={8} fontSize="lg">
                        {message}
                      </Text>
                      <Button
                        w="25%"
                        size="sm"
                        onClick={onClose}
                        colorScheme="blackAlpha"
                        color="white"
                        bgColor="black">
                        OK
                      </Button>
                    </Box>
                  ) : header === "Error" ? (
                    <Box w="100%" padding="80px 5px 80px 5px" alignItems="center">
                      <Heading mb={4} as="h2" size="lg">
                        {header}
                      </Heading>
                      <Text mb={8} fontSize="lg">
                        {message}
                      </Text>
                      <Button
                        w="25%"
                        size="sm"
                        onClick={resetHeaderData}
                        color="white"
                        bgColor="black"
                        colorScheme="blackAlpha">
                        Try Again
                      </Button>
                    </Box>
                  ) : (
                    <Box w="100%" alignItems="center">
                      <Heading mb={4} as="h3" size="md">
                        Hi Girl Boss!
                      </Heading>
                      <Text mb={8}>
                        Yuk dapatkan diskon 10% dengan terus mengikuti update dari OMG
                      </Text>
                      <InputGroup size="md" mt={8}>
                        <InputLeftAddon
                          pointerEvents="none"
                          children="+62"
                          color="white"
                          bgColor="black"
                        />
                        <Input
                          pr="4.5rem"
                          type="tel"
                          placeholder="Your Phone Number"
                          onChange={handleChange}
                          color="black"
                          bgColor="white"
                          maxLength="11"
                        />
                        <InputRightElement width="4.5rem">
                          <Button
                            h="100%"
                            w="105%"
                            size="sm"
                            onClick={handleSubmit}
                            color="white"
                            colorScheme="blackAlpha"
                            bgColor="black"
                            isLoading={loading}>
                            Sign Up
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                      {errorMessage && <Text mt={1}>{errorMessage}</Text>}
                      <Checkbox
                        name="privacyPolicy"
                        onChange={handleChecked}
                        colorScheme="gray"
                        defaultChecked>
                        <div style={{ position: "relative", marginLeft: "4px", marginTop: "4px" }}>
                          <Text marginTop={6}>
                            dengan memasukkan data ini, kamu
                            <br />
                            bersedia mengikuti&nbsp;
                            <a href="/privacy-policy" style={{ color: "#0056CC" }} target="_blank">
                              Privacy Policy
                            </a>
                            &nbsp;dari OMG
                          </Text>
                        </div>
                      </Checkbox>
                      <Box w="105%">
                        <Image
                          width="150%"
                          height="150%"
                          src={PopupImg}
                          alt="subscribe"
                          placeholder="blurred"
                          layout="constrained"
                        />
                      </Box>
                    </Box>
                  )}
                </Flex>
              ) : (
                <Flex direction="row">
                  <Box w="50%" padding="80px 5px 80px 5px">
                    <Image
                      width="120%"
                      height="110%"
                      src={PopupImg}
                      alt="subscribe"
                      placeholder="blurred"
                      layout="constrained"
                    />
                  </Box>
                  {header === "Success" ? (
                    <Box w="50%" padding="80px 5px 80px 5px" alignItems="center">
                      <Heading mb={4} as="h2" size="xl">
                        {header}
                      </Heading>
                      <Text mb={8} fontSize="lg">
                        {message}
                      </Text>
                      <Button
                        w="25%"
                        size="sm"
                        onClick={onClose}
                        colorScheme="blackAlpha"
                        color="white"
                        bgColor="black">
                        OK
                      </Button>
                    </Box>
                  ) : header === "Error" ? (
                    <Box w="50%" padding="80px 5px 80px 5px" alignItems="center">
                      <Heading mb={4} as="h2" size="lg">
                        {header}
                      </Heading>
                      <Text mb={8} fontSize="lg">
                        {message}
                      </Text>
                      <Button
                        w="25%"
                        size="sm"
                        onClick={resetHeaderData}
                        color="white"
                        bgColor="black"
                        colorScheme="blackAlpha">
                        Try Again
                      </Button>
                    </Box>
                  ) : (
                    <Box w="50%" padding="80px 5px 80px 5px" alignItems="center">
                      <Heading mb={4} as="h3" size="md">
                        Hi Girl Boss!
                      </Heading>
                      <Text mb={8}>
                        Yuk dapatkan diskon 10% dengan terus mengikuti update dari OMG
                      </Text>
                      <InputGroup size="md" mt={8}>
                        <InputLeftAddon
                          pointerEvents="none"
                          children="+62"
                          color="white"
                          bgColor="black"
                        />
                        <Input
                          pr="4.5rem"
                          type="tel"
                          placeholder="Your Phone Number"
                          onChange={handleChange}
                          color="black"
                          bgColor="white"
                          maxLength="11"
                        />
                        <InputRightElement width="4.5rem">
                          <Button
                            h="100%"
                            w="105%"
                            size="sm"
                            onClick={handleSubmit}
                            color="white"
                            bgColor="black"
                            colorScheme="blackAlpha"
                            isLoading={loading}>
                            Sign Up
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                      {errorMessage && <Text mt={1}>{errorMessage}</Text>}
                      <Checkbox
                        name="privacyPolicy"
                        onChange={handleChecked}
                        colorScheme="gray"
                        defaultChecked>
                        <div style={{ position: "relative", marginLeft: "4px", marginTop: "4px" }}>
                          <Text marginTop={6}>
                            dengan memasukkan data ini, kamu
                            <br />
                            bersedia mengikuti&nbsp;
                            <a href="/privacy-policy" style={{ color: "#0056CC" }} target="_blank">
                              Privacy Policy
                            </a>
                            &nbsp;dari OMG
                          </Text>
                        </div>
                      </Checkbox>
                    </Box>
                  )}
                </Flex>
              )}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SubsForm;
